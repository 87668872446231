import React from "react";
import { useCSVReader } from "react-papaparse";
import "./styles.css";

const journalIdParser = {
  FACTU: "ACH",
  FAC: "VEN",
};

export const FileReader = ({ onStartUpload, onEndUpload }) => {
  const { CSVReader } = useCSVReader();
  const [csvData, setCsvData] = React.useState();
  const [csvFileName, setCsvFileName] = React.useState();
  const [zoneHover, setZoneHover] = React.useState(false);

  React.useEffect(() => {
    csvData && csvFileName && onEndUpload(csvData, csvFileName);
  }, [csvData, csvFileName]);
  return (
    <CSVReader
      config={{
        chunk: () => onStartUpload(),
      }}
      onUploadAccepted={({ data, file }) => {
        const transformedData = data.reduce((acc, row) => {
          const [date, journalId, accountId, debit, credit, label, ref] = row;

          if (!!Date.parse(date)) {
            acc.push([
              new Date(date).toLocaleDateString(),
              journalIdParser[journalId] || journalId,
              accountId,
              parseFloat(debit || 0).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }),
              parseFloat(credit || 0).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }),
              label,
              ref,
            ]);
          }

          return acc;
        }, []);

        setCsvData(transformedData);
      }}
      onDragOver={(event) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setZoneHover(false);
      }}
    >
      {({ getRootProps, acceptedFile }) => (
        <div>
          <button
            type="button"
            {...getRootProps()}
            className={["browse-file", zoneHover ? "on-hover" : ""].join(" ")}
          >
            {acceptedFile && setCsvFileName(acceptedFile.name)}
            Cliquez et faites glisser votre fichier
          </button>
        </div>
      )}
    </CSVReader>
  );
};
