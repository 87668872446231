import React from "react";
import { FileReader } from "./components/Importation";
import { FileDownloader } from "./components/FileDownloader";
import { Header } from "./containers/Header";
import "./styles.css";

export const App = () => {
  const [fileData, setFileData] = React.useState({});
  const [showLoading, toggleLoading] = React.useState(false);

  return (
    <div className="main">
      <Header />
      <div className="container">
        <FileReader
          onStartUpload={() => toggleLoading(true)}
          onEndUpload={(content, filename) => {
            toggleLoading(false);
            setFileData({
              content,
              filename,
            });
          }}
        />
        <div>{fileData.content && <FileDownloader {...fileData} />}</div>
      </div>
    </div>
  );
};
