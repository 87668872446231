import React from "react";
import lim from "./assets/iconLim.webp";
import "./styles.css";

export const Header = () => {
  return (
    <div className="headContent">
      <img src={lim} alt="icone de lim" />
      <h1>CSV FIXER</h1>
    </div>
  );
};
